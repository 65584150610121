import React from "react"
import PropTypes from "prop-types"
import Player from "react-player"

const VideoEmbed = ({ src, ratio, className }) => (
  <div
    css={{
      position: "relative",
      paddingTop: `${Math.round(10000 / parseFloat(ratio)) / 100}%`,
      maxWidth: "100%",
      margin: "2em auto",
    }}
    className={className}
  >
    <Player
      controls
      url={src}
      width="100%"
      height="100%"
      css={{
        position: "absolute",
        top: 0,
        left: 0,
      }}
    />
  </div>
)

export default VideoEmbed

VideoEmbed.propTypes = {
  src: PropTypes.string.isRequired,
  ratio: PropTypes.number.isRequired,
  className: PropTypes.string,
}

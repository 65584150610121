import React from "react"
import PropTypes from "prop-types"
import katex from "katex"
import useSingleton from "hooks/useSingleton"
import { Helmet } from "react-helmet"

const macros = {}

const Katex = ({ body, className }) => {
  // Only include the Katex CSS on the page a single time
  const isFirst = useSingleton("katex")

  return (
    <>
      {isFirst && (
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/katex@0.16.0/dist/katex.min.css"
            integrity="sha384-Xi8rHCmBmhbuyyhbI88391ZKP2dmfnOl4rT9ZfRI7mLTdk1wblIUnrIq35nqwEvC"
            crossOrigin="anonymous"
          />
        </Helmet>
      )}

      <div
        // Katex is already doing some sanitizing, but this should still only
        // be used for trustworthy content (i.e. coming from Sanity Studio).
        dangerouslySetInnerHTML={{
          __html: katex.renderToString(body, {
            displayMode: true,
            macros,
            throwOnError: false,
          }),
        }}
        css={{ marginBlock: "2em" }}
        className={className}
      />
    </>
  )
}

export default Katex

Katex.propTypes = {
  body: PropTypes.string.isRequired,
  className: PropTypes.string,
}

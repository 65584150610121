import React from "react"
import PropTypes from "prop-types"
import SyntaxHighlighter from "react-syntax-highlighter"
import theme from "styles/theme"

const CodeBlock = ({ language, code }) => (
  <div
    css={theme.expandQueries({
      background: theme.colors.codeBackground,
      margin: ["50px 0", "25px 0"],
      display: "flex",
      justifyContent: "center",
    })}
  >
    <SyntaxHighlighter
      language={language}
      style={style}
      css={theme.expandFull({
        display: "block",
        overflowX: "auto",
        padding: "1em 25px",
        color: theme.colors.softText,
        fontSize: [16, 14, 12],
      })}
    >
      {code}
    </SyntaxHighlighter>
  </div>
)

export default CodeBlock

CodeBlock.propTypes = {
  language: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
}

const colors = Object.entries(theme.colors).reduce((out, [name, value]) => {
  if (name.startsWith("code")) {
    out[name.slice(4, 5).toLowerCase() + name.slice(5)] = value
  }
  return out
}, {})

const style = {
  hljs: {
    background: "transparent",
  },
  "hljs-comment": { color: colors.diminished, fontStyle: "italic" },
  "hljs-quote": { color: colors.diminished, fontStyle: "italic" },
  "hljs-doctag": { color: colors.accent2 },
  "hljs-keyword": { color: colors.accent2 },
  "hljs-formula": { color: colors.accent2 },
  "hljs-section": { color: colors.accent5 },
  "hljs-name": { color: colors.accent5 },
  "hljs-selector-tag": { color: colors.accent5 },
  "hljs-deletion": { color: colors.accent5 },
  "hljs-subst": { color: colors.accent5 },
  "hljs-literal": { color: colors.accent3 },
  "hljs-string": { color: colors.accent4 },
  "hljs-regexp": { color: colors.accent4 },
  "hljs-addition": { color: colors.accent4 },
  "hljs-attribute": { color: colors.accent4 },
  "hljs-meta-string": { color: colors.accent4 },
  "hljs-built_in": { color: colors.accent3 },
  "hljs-class .hljs-title": { color: colors.accent3 },
  "hljs-attr": { color: colors.accent3 },
  "hljs-variable": { color: colors.accent3 },
  "hljs-template-variable": { color: colors.accent3 },
  "hljs-type": { color: colors.accent3 },
  "hljs-selector-class": { color: colors.accent3 },
  "hljs-selector-attr": { color: colors.accent3 },
  "hljs-selector-pseudo": { color: colors.accent3 },
  "hljs-number": { color: colors.accent3 },
  "hljs-symbol": { color: colors.accent1 },
  "hljs-bullet": { color: colors.accent1 },
  "hljs-link": { color: colors.accent1, textDecoration: "underline" },
  "hljs-meta": { color: colors.accent1 },
  "hljs-selector-id": { color: colors.accent1 },
  "hljs-title": { color: colors.accent1 },
  "hljs-emphasis": { fontStyle: "italic" },
  "hljs-strong": { fontWeight: "bold" },
}

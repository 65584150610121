import React from "react"
import PropTypes from "prop-types"
import theme from "styles/theme"
import BigImage from "components/blocks/BigImage"

const ArticleImage = ({ asset, caption }) => (
  <BigImage
    asset={asset}
    caption={caption}
    css={{
      margin: "2.25em auto 4em",
      [theme.mq.mobile]: {
        margin: "20px auto 30px",
        padding: "0 12px",
      },
    }}
  />
)

export default ArticleImage

ArticleImage.propTypes = {
  asset: PropTypes.oneOfType([
    PropTypes.shape({
      _ref: PropTypes.string.isRequired,
    }).isRequired,
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  ]).isRequired,
  caption: PropTypes.string,
}

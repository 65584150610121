import React from "react"
import PropTypes from "prop-types"
import FilePlayer from "react-player/file"

const SimpleMuxVideo = ({ asset: { playbackId }, ...props }) => (
  <FilePlayer
    url={`https://stream.mux.com/${playbackId}.m3u8`}
    width="100%"
    height="auto"
    controls
    playsinline
    {...props}
  />
)

export default SimpleMuxVideo

SimpleMuxVideo.propTypes = {
  asset: PropTypes.shape({
    playbackId: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
}

import ArticleImage from "components/ArticleImage"
import ArticleImageSmall from "components/ArticleImageSmall"
import ArticleImages from "components/ArticleImages"
import CodeBlock from "components/CodeBlock"
import ContentBlock, { nodeToProps } from "components/ContentBlock"
import Katex from "components/Katex"
import Layout from "components/Layout"
import Metadata from "components/Metadata"
import ShareButtons from "components/ShareButtons"
import SimpleMuxVideo from "components/SimpleMuxVideo"
import VideoEmbed from "components/VideoEmbed"
import IntroText from "components/blocks/IntroText"
import RelatedArticles from "components/blocks/RelatedArticles"
import SimpleHero from "components/blocks/SimpleHero"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const Article = ({
  data: {
    page: {
      title,
      publicationDate,
      showDate,
      authorNames,
      heroImage,
      content,
      relatedArticles,
      mode,
      metadata,
    },
  },
  location,
}) => {
  return (
    <Layout mode={mode}>
      <Metadata {...metadata} />
      <SimpleHero image={heroImage} />

      <div
        css={theme.expandFull({
          padding: ["80px 25px 0", "60px 25px 0", "30px 25px 0"],
          maxWidth: [840, 630, 500],
          margin: ["0 auto 24px", null, "0 auto 10px"],
        })}
      >
        <h1 css={{ ...theme.h1, textAlign: "center" }}>{title}</h1>

        <div css={{ ...theme.p3, opacity: 0.6, marginTop: 32 }}>
          {showDate && (
            <div css={{ display: "inline-block", marginRight: "2em" }}>
              <strong>Date</strong> {publicationDate}
            </div>
          )}
          <div css={{ display: "inline-block" }}>
            <strong>Author{authorNames.length > 1 && "s"}</strong>{" "}
            {authorNames.join(", ")}
          </div>
        </div>
      </div>

      <ContentBlock
        content={content}
        serializers={{
          types: {
            bigImage: nodeToProps(ArticleImage),
            bigImages: nodeToProps(ArticleImages),
            smallImage: nodeToProps(ArticleImageSmall),
            code: nodeToProps(CodeBlock),
            "mux.video": nodeToProps(MuxVideo),
            videoEmbed: nodeToProps(ArticleVideoEmbed),
            latex: nodeToProps(Katex, { css: articleBlockStyles }),
          },
        }}
        blockOverrides={{
          /* eslint-disable react/display-name, react/prop-types */
          normal: (props) => <p css={articleBlockStyles} {...props} />,
          h2: (props) => (
            <h2
              css={[
                articleBlockStyles,
                { ...theme.h3, opacity: 1, marginTop: "2em" },
              ]}
              {...props}
            />
          ),
          h3: (props) => (
            <h3
              css={[
                articleBlockStyles,
                { ...theme.h4, opacity: 1, marginTop: "2em" },
              ]}
              {...props}
            />
          ),
          pullquote: (props) => (
            <IntroText
              text={props.children}
              extraWide
              css={theme.expandFull({
                marginTop: [100, 60, 30],
                marginBottom: [100, 60, 30],
              })}
            />
          ),
          ul: (props) => (
            <ul
              css={{ ...articleBlockStyles, paddingLeft: "3em" }}
              {...props}
            />
          ),
          ol: (props) => (
            <ol
              css={{ ...articleBlockStyles, paddingLeft: "3em" }}
              {...props}
            />
          ),
          /* eslint-enable react/display-name, react/prop-types */
        }}
        css={{ marginBottom: 70 }}
      />

      <ShareButtons
        path={location.pathname}
        title={title}
        css={{
          ...articleBlockStyles,
          marginTop: -20,
          marginBottom: 70,
        }}
      />

      {relatedArticles.length > 0 && (
        <RelatedArticles title="Related articles" articles={relatedArticles} />
      )}
    </Layout>
  )
}

export default Article

Article.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string.isRequired,
      publicationDate: PropTypes.string.isRequired,
      showDate: PropTypes.bool,
      authorNames: PropTypes.arrayOf(PropTypes.string).isRequired,
      heroImage: PropTypes.shape({
        asset: PropTypes.shape({
          _id: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      content: PropTypes.array.isRequired,
      relatedArticles: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          slug: PropTypes.shape({ current: PropTypes.string.isRequired })
            .isRequired,
          coverImage: PropTypes.shape({
            asset: PropTypes.shape({
              _id: PropTypes.string.isRequired,
            }).isRequired,
          }),
        }).isRequired
      ).isRequired,
      mode: PropTypes.oneOf(["light", "dark"]).isRequired,
      metadata: PropTypes.shape(Metadata.propTypes).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

const MuxVideo = (props) => (
  <SimpleMuxVideo
    css={[articleBlockStyles, { marginTop: "2em", marginBottom: "2em" }]}
    {...props}
  />
)

const ArticleVideoEmbed = (props) => (
  <div css={articleBlockStyles}>
    <VideoEmbed {...props} />
  </div>
)

const articleBlockStyles = theme.expandFull({
  maxWidth: [840, 630, 500],
  paddingLeft: 25,
  paddingRight: 25,
  marginLeft: "auto",
  marginRight: "auto",
  opacity: 0.8,
})

export const query = graphql`
  query ArticleQuery($id: String!) {
    page: sanityArticle(_id: { eq: $id }) {
      title
      publicationDate(formatString: "MMMM D, YYYY")
      showDate
      authorNames
      heroImage {
        ...ImageWithPreview
      }
      content: _rawContent(resolveReferences: { maxDepth: 3 })
      relatedArticles {
        ...ArticleTile
      }
      mode
      metadata {
        ...Metadata
      }
    }
  }
`

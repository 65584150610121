import SmallImage from "components/blocks/SmallImage"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const ArticleImageSmall = ({ asset, caption }) => (
  <SmallImage
    asset={asset}
    caption={caption}
    css={{
      margin: "2.25em auto 4em",
      [theme.mq.mobile]: {
        margin: "20px auto 30px",
        padding: "0 12px",
      },
    }}
  />
)

export default ArticleImageSmall

ArticleImageSmall.propTypes = {
  asset: PropTypes.oneOfType([
    PropTypes.shape({
      _ref: PropTypes.string.isRequired,
    }).isRequired,
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  ]).isRequired,
  caption: PropTypes.string,
}

import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import theme from "styles/theme"
import BigImages from "components/blocks/BigImages"

const ArticleImages = ({ images }) => (
  <BigImages
    images={images}
    css={{
      margin: "2.25em auto 4em",
      [theme.mq.mobile]: {
        margin: "20px auto 30px",
      },
    }}
  />
)

export default ArticleImages

ArticleImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      asset: PropTypes.oneOfType([
        PropTypes.shape({
          _ref: PropTypes.string.isRequired,
        }).isRequired,
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
        }).isRequired,
      ]).isRequired,
    }).isRequired
  ).isRequired,
}

export const query = graphql`
  fragment ArticleImagesBlock on SanityArticleImages {
    images {
      caption
    }
  }
`

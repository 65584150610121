import React from "react"
import PropTypes from "prop-types"
import {
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share"
import theme from "styles/theme"

const ShareButtons = ({ path, title, className }) => {
  const url = fullUrl(path)

  return (
    <div
      css={{
        ...theme.p3,
        color: theme.colors.secondaryText,
        display: "flex",
      }}
      className={className}
    >
      <div css={{ fontWeight: 500, marginRight: "0.25em" }}>Share</div>
      <TwitterShareButton url={url} title={title} css={shareButtonStyles}>
        Twitter
      </TwitterShareButton>
      ,
      <LinkedinShareButton url={url} title={title} css={shareButtonStyles}>
        Linkedin
      </LinkedinShareButton>
      ,
      <EmailShareButton url={url} subject={title} css={shareButtonStyles}>
        Mail
      </EmailShareButton>
    </div>
  )
}

export default ShareButtons

ShareButtons.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}

const fullUrl = (path) => {
  const host = process.env.GATSBY_HOST
  return path.endsWith("/") ? host + path : host + path + "/"
}

const shareButtonStyles = {
  color: "inherit",
  marginLeft: "0.25em",
  position: "relative",
  transition: "color 300ms",
  ":after": {
    content: "''",
    position: "absolute",
    left: 0,
    bottom: 2,
    width: "100%",
    height: 1,
    background: theme.colors.secondaryText,
    opacity: 0.6,
    transition: "background 300ms, transform 300ms",
    transformOrigin: "top center",
  },
  ":hover": {
    color: `${theme.colors.accentColor} !important`,
    ":after": {
      background: theme.colors.accentColor,
      transform: "scaleY(2)",
    },
  },
}
